<template>
  <div class="page">
    <div class="map" id="map" ref="map"></div>
    <!-- <MyMap :address="mySpecialAddress" /> -->
    <!-- <div
      class="p-v-10"
      v-for="address in mySpecialAddress"
      :key="address.articleId"
      @click="goDetail(address.articleId)"
    >
      {{ address.article.title }}
    </div> -->
    <div
      class="btn_downloadFile flex-ct"
      v-download="{
        url: downloadFile.path,
        fileName: downloadFile.fileName
      }"
    >
      点击下载指南手册
    </div>
    <NavigatorBar />
  </div>
</template>

<script>
import { mapState } from "vuex/dist/vuex.common.js";
// import MyMap from "./map.vue";

const LKMap = window.LKMap;
export default {
  components: {
    // MyMap
  },
  computed: {
    ...mapState(["isMobile"]),
    ...mapState(["specialAddress", "articles", "downloadFile"]),
    mySpecialAddress() {
      return this.specialAddress.map((item) => {
        const index = this.articles.findIndex(
          (article) => article.id === item.articleId
        );
        if (index > -1) {
          return {
            ...item,
            article: this.articles[index]
          };
        }
        return item;
      });
    }
  },
  data() {
    return {
      map: null,
      lnglats: [
        {
          position: [116.40007495409134, 39.90933312432409],
          name: "太庙",
          aid: "taimiao",
          iconData: {
            size: [1121, 607],
            image: require("@/images/map/劳动人民文化宫.png")
          }
        },
        {
          position: [116.3969244533248, 39.917119166961925],
          name: "故宫",
          aid: "gugong",
          iconData: {
            size: [1176, 715],
            image: require("@/images/map/故宫.png")
          }
        },
        {
          position: [116.41193497184122, 39.88187170596103],
          name: "天坛公园",
          aid: "tiantan",
          iconData: {
            size: [1112, 738],
            image: require("@/images/map/天坛公园.png")
          }
        },

        {
          position: [115.826681, 39.674037],
          name: "上方山国家森林公园",
          iconData: {
            size: [488, 387],
            image: require("@/images/map/上方山国家森林公园.png")
          }
        },
        {
          position: [116.094298, 39.868089],
          name: "戒台寺",
          iconData: {
            size: [421, 373],
            image: require("@/images/map/戒台寺.png")
          }
        },
        {
          position: [116.030959, 39.902727],
          name: "潭柘寺",
          iconData: {
            size: [469, 268],
            image: require("@/images/map/潭柘寺.png")
          }
        },
        {
          position: [116.111253, 40.053508],
          name: "大觉寺",
          iconData: {
            size: [412, 293],
            image: require("@/images/map/大觉寺.png")
          }
        },
        {
          position: [116.415493, 39.954045],
          name: "地坛公园",
          iconData: {
            size: [656, 271],
            image: require("@/images/map/地坛公园.png")
          }
        },
        {
          position: [116.188621, 39.990675],
          name: "香山公园",
          iconData: {
            size: [392, 363],
            image: require("@/images/map/香山公园.png")
          }
        },
        {
          position: [116.210314337, 39.998253172],
          name: "国家植物园",
          iconData: {
            size: [476, 271],
            image: require("@/images/map/国家植物园.png")
          }
        },
        {
          position: [116.626708, 40.383599],
          name: "红螺寺",
          iconData: {
            size: [372, 334],
            image: require("@/images/map/红螺寺.png")
          }
        },
        {
          position: [116.227601, 40.294399],
          name: "明十三陵",
          iconData: {
            size: [407, 270],
            image: require("@/images/map/明十三陵.png")
          }
        },
        {
          position: [116.394747, 39.911077],
          name: "中山公园",
          iconData: {
            size: [554, 367],
            image: require("@/images/map/中山公园.png")
          }
        },
        {
          position: [116.329945, 39.944384],
          name: "五塔寺",
          iconData: {
            size: [377, 383],
            image: require("@/images/map/五塔寺.png")
          }
        },
        {
          position: [116.388985, 39.926754],
          name: "北海公园",
          iconData: {
            size: [404, 380],
            image: require("@/images/map/北海公园.png")
          }
        },
        {
          position: [116.396767, 39.925299],
          name: "景山公园",
          iconData: {
            size: [391, 387],
            image: require("@/images/map/景山公园.png")
          }
        },
        {
          position: [116.413852, 39.946478],
          name: "孔庙和国子监博物馆",
          iconData: {
            size: [504, 338],
            image: require("@/images/map/孔庙和国子监博物馆.png")
          }
        }
      ],
      markers: [],
      icons: []
    };
  },
  methods: {
    goDetail(id) {
      this.$router.push("/beijing-ancient-tree-map-detail?id=" + id);
    },
    showInfoM(data) {
      console.log(data.target.extData, data.target);
      if (data?.target?.extData?.aid) {
        this.$router.push(
          "/beijing-ancient-tree-map-detail?id=" + data?.target?.extData?.aid
        );
      }
    },
    addMarkers() {
      const markers = [];
      const map = this.map;
      const lnglats = this.lnglats;

      for (var i in lnglats) {
        const otherProps = {};
        if (lnglats[i].aid) {
          otherProps.zIndex = 1000;
        }
        if (lnglats[i].iconData) {
          const icon = new LKMap.Icon({
            size: new LKMap.Size(
              ...lnglats[i].iconData.size.map((_i) => {
                if (lnglats[i].aid) {
                  return _i * 0.2;
                }
                return _i * 0.25;
              })
            ),
            image: lnglats[i].iconData.image
          });
          this.icons.push(icon);
          otherProps.icon = icon;
        } else {
          otherProps.label = {
            content: lnglats[i].name
          };
        }

        markers[i] = new LKMap.Marker({
          map: map,
          position: new LKMap.LngLat(
            lnglats[i].position[0],
            lnglats[i].position[1]
          ),
          anchor: "bottom",
          extData: {
            id: i,
            name: lnglats[i].name,
            aid: lnglats[i].aid
          },
          ...otherProps
        });
      }

      markers.map((marker) => {
        if (marker.extData?.aid) {
          marker.setAnimation("LKMAP_ANIMATION_BOUNCE");
          marker.on("click", this.showInfoM);
        }
      });
      if (!this.isMobile) {
        this.map.setFitView(markers.map((i) => i.extData.aid));
      }
      return markers;
    },
    resizeMarkers() {
      const zoomLevel = this.map.getZoom();
      const lnglats = this.lnglats;
      const rate = 0.5;
      this.lnglats.forEach((lnglat, index) => {
        const icon = this.icons[index];
        if (index === 0)
          console.log("zoomend", zoomLevel, icon.getSize().getWidth());
        icon.setSize(
          new LKMap.Size(
            lnglat.iconData.size[0] * rate,
            lnglat.iconData.size[1] * rate
          )
        );
      });
      this.map.remove(this.markers);
    }
  },
  mounted() {
    setTimeout(() => {
      var position = new LKMap.LngLat(116.40007495409134, 39.90933312432409);
      const map = new LKMap.Map("map", {
        center: position,
        zoom: 13,
        // minZoom: 11,
        // maxZoom: 14,
        style: "lkmap://styles/beb34c3df7fc4dc2b2c6786df40a6de7"
        // maxBounds: new LKMap.Bounds(
        //   new LKMap.LngLat(115.619235676, 39.451017352),
        //   new LKMap.LngLat(117.229513559, 40.624176608)
        // )
      });
      map.on("load", () => {});
      map.on("zoomend", () => {
        // this.resizeMarkers();
      });
      this.map = map;
      this.markers = this.addMarkers();
    }, 0);

    // const T = window.T;
    // var map;
    // var zoom = 12;
    // map = new T.Map("map");
    // map.centerAndZoom(new T.LngLat(116.40769, 39.89945), zoom);
  },
  activated() {
    this.map && this.map.resize();
  },
  destroyed() {
    this.map && this.map.destroy();
  }
};
</script>

<style lang="scss" scoped>
.page,
.map {
  width: 100%;
  height: 100%;
  ::v-deep .LK-map-logo {
    display: none;
  }
}

.btn_downloadFile {
  background: #40bf65;
  font-size: 10px;
  color: #ffffff;
  border-radius: 20px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
}
</style>
